import React, { useEffect, useState } from 'react';
import { Container, Box, TextField, MenuItem, Grid, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, Link } from 'react-router-dom';
import './LearningPage.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SchoolIcon from '@mui/icons-material/School';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Sidebar = styled('aside')({
  backgroundColor: '#1f1f2e',
  width: '250px',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  overflowY: 'auto',
  zIndex: 1000,
});

const SidebarHeader = styled('div')({
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid #2a2b3c',
});

const SidebarHeaderImage = styled('img')({
  width: '65px',
  borderRadius: '50%',
  marginRight: '15px',
});

const SidebarHeaderText = styled('div')({
  color: '#9CA3AF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const BetaText = styled('p')({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '0.8rem',
  color: 'red',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  margin: 0,
  position: 'relative',
  marginBottom: '4px',
});

const TerborevText = styled('p')({
  fontFamily: 'Roboto, sans-serif',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
  margin: 0,
});

const SidebarItem = styled(Link)(({ theme }) => ({
  padding: '15px 20px',
  textDecoration: 'none',
  color: '#a1a0a0',
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  transition: 'background 0.3s',
  borderRight: '2px solid transparent',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#2d2d2d',
  },
  '& svg': {
    marginRight: '10px',
  },
  '&.sidebar-item-selected': {
    color: '#fff',
    borderRight: '2px solid #fff',
  }
}));

function LearningPage() {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null);

  const [examName, setExamName] = useState('');
  const [examDuration, setExamDuration] = useState('');
  const [examDifficulty, setExamDifficulty] = useState('');
  const [examTopic, setExamTopic] = useState('');

  useEffect(() => {
    async function fetchUserProfile() {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          setUserProfile(data.user);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        navigate('/');
      }
    }

    fetchUserProfile();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const handleCreateExam = () => {
    console.log("Exam Created:", { examName, examDuration, examDifficulty, examTopic });
  };

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  const chartData = {
    labels: ['Math', 'Science', 'History', 'Geography', 'English'],
    datasets: [
      {
        label: 'Performance',
        backgroundColor: '#6A67CE',
        borderColor: '#5A5CFF',
        borderWidth: 1,
        hoverBackgroundColor: '#484BB1',
        hoverBorderColor: '#5A5CFF',
        data: [85, 90, 80, 75, 95],
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#252636', color: '#a1a0a0', height: '100vh', position: 'relative', width: '100%' }}>
      <Sidebar>
        <SidebarHeader>
          <SidebarHeaderImage src={`${process.env.PUBLIC_URL}/terborev-logo.png`} alt="Terborev Logo" />
          <SidebarHeaderText>
            <BetaText>BETA</BetaText>
            <TerborevText>TERBOREV</TerborevText>
          </SidebarHeaderText>
        </SidebarHeader>
        <SidebarItem to="/learning" className="sidebar-item-selected">
          <AddCircleOutlineIcon /> Create Exam
        </SidebarItem>
        <SidebarItem to="/exams">
          <SchoolIcon /> My Exams
        </SidebarItem>
        <SidebarItem to="/results">
          <HomeIcon /> Exam Results
        </SidebarItem>
        <SidebarItem to="/profile">
          <AccountCircleIcon /> Profile
        </SidebarItem>
        <SidebarItem as="button" onClick={handleLogout} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
          <ExitToAppIcon /> Logout
        </SidebarItem>
      </Sidebar>

      <Container component="main" disableGutters sx={{ flexGrow: 1, ml: '250px', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 'calc(100% - 250px)', maxWidth: '100%' }}>
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', zIndex: -1 }} />
        <Box sx={{ position: 'relative', zIndex: 1, width: '100%' }}>
          <Box component="div" sx={{ height: '300px', mb: 4 }}>
            <Bar data={chartData} options={chartOptions} />
          </Box>

          <Box component="form" noValidate autoComplete="off" sx={{ width: '100%' }}>
            <Grid container spacing={3} sx={{ width: '100%', m: 0 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Exam Name"
                  value={examName}
                  onChange={(e) => setExamName(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: '#a1a0a0', fontSize: '1.2rem' },
                  }}
                  InputProps={{
                    style: { color: '#a1a0a0', fontSize: '1.2rem' },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Duration (minutes)"
                  type="number"
                  value={examDuration}
                  onChange={(e) => setExamDuration(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: '#a1a0a0', fontSize: '1.2rem' },
                  }}
                  InputProps={{
                    style: { color: '#a1a0a0', fontSize: '1.2rem' },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  label="Difficulty"
                  value={examDifficulty}
                  onChange={(e) => setExamDifficulty(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: '#a1a0a0', fontSize: '1.2rem' },
                  }}
                  InputProps={{
                    style: { color: '#a1a0a0', fontSize: '1.2rem' },
                  }}
                >
                  <MenuItem value="easy">Easy</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="hard">Hard</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Topic"
                  value={examTopic}
                  onChange={(e) => setExamTopic(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: '#a1a0a0', fontSize: '1.2rem' },
                  }}
                  InputProps={{
                    style: { color: '#a1a0a0', fontSize: '1.2rem' },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ backgroundColor: '#484BB1', color: '#ffffff', fontSize: '1.2rem', padding: '12px 24px', '&:hover': { backgroundColor: '#3b3e97' } }}
                  onClick={handleCreateExam}
                >
                  Create Exam
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default LearningPage;
