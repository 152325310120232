// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import LearningPage from './LearningPage';
import ResetPassword from './ResetPassword'

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/learning" element={<LearningPage />} />
                <Route path="/reset_password" element={<ResetPassword />} />
            </Routes>
        </Router>
    );
}

export default App;
