import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import Typewriter from 'typewriter-effect';
import { Analytics } from "@vercel/analytics/react"
function HomePage() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [loginError, setLoginError] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [resetMessage, setResetMessage] = useState('');
    const [showResetForm, setShowResetForm] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePasswordReset = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/forgot_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: resetEmail }),
            });

            const data = await response.json();

            if (response.ok) {
                setResetMessage('A password reset link has been sent to your email.');
            } else {
                setResetMessage(data.message || 'Password reset failed');
            }
        } catch (error) {
            console.error('Password reset request failed', error);
            setResetMessage('Password reset request failed');
        }
    };

    console.log('API URL:', process.env.REACT_APP_API_URL);
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.setItem('token', data.access_token);
                navigate('/learning');
            } else {
                setLoginError(data.message || 'Login failed');
            }
        } catch (error) {
            console.error('Login request failed', error);
            setLoginError('Login request failed');
        }
    };

    return (
        <div className="home-page">
            <video autoPlay loop muted className="background-video">
                <source src={`${process.env.PUBLIC_URL}/backgroundlive.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className="background-overlay"></div>

            <header className="header">
                <div className="header-content">
                    <img src={`${process.env.PUBLIC_URL}/terborev-logo.png`} alt="TerboRev Logo" className="logo" />
                    <span className="header-text">TerboRev</span>
                </div>
            </header>

            <div className="typewriter-container">
                <Typewriter
                    options={{
                        strings: ['AI-Powered Practice for Your Certifications with TerboRev.'],
                        autoStart: true,
                        loop: true,
                    }}
                />
            </div>

            <div className="form-container">
                <h2 className="form-heading">Sign In</h2>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input type="email" name="email" placeholder="Email" className="form-input" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <input type="password" name="password" placeholder="Password" className="form-input" value={formData.password} onChange={handleChange} />
                    </div>
                    <button type="submit" className="form-button">Login</button>
                </form>

                {loginError && <p className="login-error">{loginError}</p>}

                {!showResetForm && (
                    <button className="forgot-password-link" onClick={() => setShowResetForm(true)}>
                        Forgot Password?
                    </button>
                )}

                {showResetForm && (
                    <div className="forgot-password-container">
                        <div className="forgot-password-form">
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={resetEmail}
                                onChange={(e) => setResetEmail(e.target.value)}
                            />
                            <button className="form-button" onClick={handlePasswordReset}>
                                Send Reset Link
                            </button>
                            {resetMessage && <p className="reset-message">{resetMessage}</p>}
                        </div>
                    </div>
                )}
            </div>

            <footer className="footer">
                <p className="footer-text">© 2024 TerboRev. All rights reserved.</p>
                <p className="footer-text">Developed by Terryn Booth</p>
            </footer>
        </div>
    );
}

 <Analytics />
export default HomePage;
